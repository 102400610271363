import React from "react"
import "twin.macro"

const PronomenSelect = ({
  name,
  label,
  multiple = false,
  required = false,
  register,
}) => {
  return (
    <>
      <label tw="mb-2 block font-regular text-sm leading-none">
        {label}
        {required && "*"}
      </label>
      <select
        tw="w-full border-2 border-transparent px-2 pt-2.5 pb-2.5 text-base outline-none focus:border-anthracite-500 focus:ring-transparent md:px-3"
        {...register}
        multiple={multiple}
        required={required}
        defaultValue={"Auswählen"}
      >
        <option disabled>Auswählen</option>
        <option value="Sie">Sie</option>
        <option value="Er">Er</option>
      </select>
    </>
  )
}

export default PronomenSelect
