import "./form.css"
import "twin.macro"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import axios from "axios"
import Box from "./box"
import Checkbox from "./checkbox2"
import Column from "./column"
import Columns from "./columns"
import ColumnsThree from "./columns-three"
import ErrorMessage from "./error-message"
import Field from "./field"
import PronunSelect from "./pronomen-select"
import AnredeSelect from "./anrede-select"
import Inline from "./inline"
import Input from "./input2"
import Radio from "./radio"
import React, { useState } from "react"
import Submit from "./submit"
import SuccessMessage from "./success-message"
import TextArea from "./textarea"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const ApplicationForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      attend_monday: "",
      attend_tuesday: "",
      workshop_first_choice: "",
      workshop_second_choice: "",
      firstname: "",
      lastname: "",
      pronomen: "",
      strasse: "",
      hausnummer: "",
      stadt: "",
      plz: "",
      email: "",
      vegan: false,
      glutenfrei: false,
      laktosefrei: false,
      assistant_needed: false,
      assistant: "",
      support_needed: false,
      support: "",
      child_support_needed: false,
      child_support: "",
      other: "",
      gdprpermission: false,
      picturespermission: false,
      newsletter: false,
    },
  })

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [duplicateUserError, setDuplicateUserError] = useState(false)

  const assistantNeeded = true
  const supportNeeded = true
  const childSupportNeeded = true

  const submitForm = async (data) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const response = await axios.post(
        `/api/attends`,
        JSON.stringify(data),
        options
      )
      setIsSuccessfullySubmitted(response.status === 200)
    } catch (e) {
      if (e.response.status >= 400) {
        if (e.response.status === 403) {
          setDuplicateUserError(true)
          return
        }
        setSubmitError(true)
      }
    }
  }

  return (
    <>
      {!isSuccessfullySubmitted && (
        <>

          <Accordion>

              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <div tw="text-xl font-bold">
                          <span tw="font-bold text-gray-700 bg-white py-3 px-4 me-3 rounded-full">1</span>
                          Teilnahme</div>
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Field>
                      <Box sand>
                      <div tw="mt-4 mb-4 text-sm">Zur besseren Planbarkeit möchten wir Sie bitten, vorab anzugeben, ob Sie am gesamten Programm teilnehmen werden oder nur am Vormittagsprogramm oder ausschließlich an der Abendveranstaltung interessiert sind.</div>
                        <Radio
                          label="Ich möchte ganztägig (von 10:00 Uhr bis 22:00 Uhr) dabei sein."
                          value="vor Ort"
                          register={register("attend_monday")}
                          data-testid="attend_monday_vor_ort"
                        />
                        <Radio
                          label="Ich möchte nur tagsüber (von 10:00 Uhr bis 17:30 Uhr) teilnehmen."
                          value="online"
                          register={register("attend_monday")}
                        />
                        <Radio
                          label="Ich möchte ausschließlich an der Abendveranstaltung mit Get-together (von 18:30 Uhr bis 22:00 Uhr) teilnehmen."
                          value="online"
                          register={register("attend_monday")}
                        />

                      <button
                        tw="mt-6 bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                        title="WEITER"
                        onClick={() => navigate(-1)}
                      >
                        WEITER
                      </button>

                      </Box>
                    </Field>
                  </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                          Is free will real or just an illusion?
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                      </p>
                  </AccordionItemPanel>
              </AccordionItem>

          </Accordion>



          <span tw="mb-8 block text-base">*Pflichtfeld</span>
          <form onSubmit={handleSubmit(submitForm)}>
            <Field>
              <Box sand>
                <div tw="text-xl font-bold">
                  <span tw="font-bold text-gray-700 bg-white py-3 px-4 me-3 rounded-full">1</span>
                  Teilnahme</div>
                <div tw="mt-4 mb-4 text-sm">Zur besseren Planbarkeit möchten wir Sie bitten, vorab anzugeben, ob Sie am gesamten Programm teilnehmen werden oder nur am Vormittagsprogramm oder ausschließlich an der Abendveranstaltung interessiert sind.</div>
                <Radio
                  label="Ich möchte ganztägig (von 10:00 Uhr bis 22:00 Uhr) dabei sein."
                  value="vor Ort"
                  register={register("attend_monday")}
                  data-testid="attend_monday_vor_ort"
                />
                <Radio
                  label="Ich möchte nur tagsüber (von 10:00 Uhr bis 17:30 Uhr) teilnehmen."
                  value="online"
                  register={register("attend_monday")}
                />
                <Radio
                  label="Ich möchte ausschließlich an der Abendveranstaltung mit Get-together (von 18:30 Uhr bis 22:00 Uhr) teilnehmen."
                  value="online"
                  register={register("attend_monday")}
                />

              <button
                tw="mt-6 bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                title="WEITER"
                onClick={() => navigate(-1)}
              >
                WEITER
              </button>

              </Box>
            </Field>

            <Field lgSpacing>
              <Box sand>
                <Field>
                  <div tw="text-xl font-bold">
                    <span tw="font-bold text-gray-700 bg-white py-3 px-4 me-3 rounded-full">2</span>
                    Workshops
                  </div>
                  <div tw="mt-6 mb-4 text-sm font-bold">Max. 3 Workshops pro Teilnehmerin</div>
                  <div tw="mb-5 text-sm underline">Zur Programmübersicht</div>

                  <Checkbox
                    title="Gemeinsam stark für Mädchen*!"
                    label="Empowerment durch Vernetzung von Jugend- und Gleichstellungspolitik"
                    tooltiptext="Text1"
                    register={register("glutenfrei")}
                  />

                  <Checkbox
                    title="LOREM IPSUM"
                    label="Die gedankliche Reise in die dystopische Vorstellung „Keine Gleichstellung der Geschlechter- was bedeutet das konkret?"
                    tooltiptext="Text2"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Gleichstellungsbeauftragte als Agentinnen des Wandels"
                    label="Die gedankliche Reise in die dystopische Vorstellung „Keine Gleichstellung der Geschlechter- was bedeutet das konkret?"
                    tooltiptext="Text2"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Anti-Sexismus Workshop - WIR reagieren gemeinsam"
                    label="Methodentraining gegen Sexismus im Alltag für Arbeitende oder Inanspruchnehmende des Gesundheitssystems"
                    tooltiptext="Text2"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Closing Gender Gaps - Gleichstellung auf dem Arbeitsmarkt regional gestalten"
                    label="Mit regionalen Gleichstellungsstrategien mehr Frauen in gut bezahlte Erwerbsarbeit bringen"
                    tooltiptext="Text__"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Vernetzung im Dreiländereck"
                    label="Grenzüberschreitender Austausch zwischen Deutschland, Polen und Tschechien"
                    tooltiptext="Text__"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Umgang mit geschlechtsspezifischem Hass und Anfeindungen im Netz"
                    label=""
                    tooltiptext="Text__"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Die Zukunft der Arbeit: Neue Arbeitszeitmodelle als Chance für Gleichstellung?"
                    label="Über die Notwendigkeit und Umsetzung einer Paritätsgesetzgebung für die Bundesrepublik Deutschland"
                    tooltiptext="Text__"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />

                  <Checkbox
                    title="Jenseits der Zweigeschlechtlichkeit: Herausforderung oder Chance für Gleichstellung?"
                    label=""
                    tooltiptext="Text__"
                    register={register("glutenfrei")}
                    tw="mt-10"
                  />
                </Field>

                <button
                  tw="mt-6 bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                  title="WEITER"
                  onClick={() => navigate(-1)}
                >
                  WEITER
                </button>

              </Box>

            </Field>


            <Field lgSpacing>
              <Box sand>

                <Field>
                  <div tw="text-xl font-bold">
                    <span tw="font-bold text-gray-700 bg-white py-3 px-4 me-3 rounded-full">3</span>
                    Persönliche Daten
                  </div>
                  <div tw="mt-6 mb-4 text-sm font-bold">Max. 3 Workshops pro Teilnehmerin</div>
                  <div tw="mb-5 text-sm underline">Zur Programmübersicht</div>
                </Field>

                <Field>
                  <ColumnsThree>
                    <Field>
                      <Input
                        register={register("firstname", {
                          required: true,
                        })}
                        required
                        fullwidth
                        label="Titel"
                      />
                      {errors?.firstname ? (
                        <div tw="relative">
                          <div tw="absolute top-0 left-0 text-sm text-red-500">
                            Dies ist ein Pflichtfeld
                          </div>
                        </div>
                      ) : null}
                    </Field>

                    <Field>
                      <Input
                        register={register("firstname", {
                          required: true,
                        })}
                        required
                        fullwidth
                        label="Vorname"
                      />
                      {errors?.firstname ? (
                        <div tw="relative">
                          <div tw="absolute top-0 left-0 text-sm text-red-500">
                            Dies ist ein Pflichtfeld
                          </div>
                        </div>
                      ) : null}
                    </Field>
                    <Field>
                      <Input
                        register={register("lastname", {
                          required: true,
                        })}
                        required
                        fullwidth
                        label="Nachname"
                      />
                      {errors?.lastname ? (
                        <div tw="relative">
                          <div tw="absolute top-0 left-0 text-sm text-red-500">
                            Dies ist ein Pflichtfeld
                          </div>
                        </div>
                      ) : null}
                    </Field>
                  </ColumnsThree>
                  <Columns>
                    <Field>
                      <PronunSelect
                        register={register("name", {
                          required: true
                        })}
                        name="pronomen"
                        label="Pronomen"
                      />
                    </Field>

                    <Field>
                      <AnredeSelect
                        register={register("name", {
                          required: true
                        })}
                        name="anrede"
                        label="Anrede"
                      />
                    </Field>

                    <Field>
                      <Input
                        fullwidth
                        register={register("email", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                        required
                        label="E-Mailadresse"
                      />
                      {errors?.email && errors?.email.type === "required" && (
                        <div tw="relative">
                          <div tw="absolute top-0 left-0 text-sm text-red-500">
                            Dies ist ein Pflichtfeld
                          </div>
                        </div>
                      )}
                      {errors?.email && errors?.email.type === "pattern" && (
                        <div tw="relative">
                          <div tw="absolute top-0 left-0 text-sm text-red-500">
                            Dieses Feld muss eine gültige E-Mail-Adresse enthalten
                          </div>
                        </div>
                      )}
                    </Field>
                  </Columns>

                  <Column>
                    <Field>
                      <Checkbox
                        title="Ich bin aktiv in die Workshop-Gestaltung eingebunden (z. B. Moderator*in, Panelist*in)"
                        register={register("glutenfrei")}
                      />
                      <Checkbox
                        title="Ich vertrete meine Organisation an einem Messestand"
                        register={register("glutenfrei")}
                      />
                      <Checkbox
                        title="Ich vertrete meine Organisation an einem Messestand"
                        register={register("glutenfrei")}
                      />
                    </Field>

                    <Field>
                      <Field>
                        <div tw="mb-5 text-sm">Ich esse</div>
                      </Field>
                      <Checkbox
                        title="vegan"
                        register={register("glutenfrei")}
                      />
                      <Checkbox
                        title="glutenfrei"
                        register={register("glutenfrei")}
                      />
                      <Checkbox
                        title="laktosefrei"
                        register={register("glutenfrei")}
                      />

                      <div tw="mb-5 text-sm">Beim Gleichstellungstag werden grundsätzlich vegetarische Speisen angeboten.</div>
                    </Field>


                    <Field lgSpacing>
                      <div tw="relative col-span-2">
                        <div tw="flex flex-col items-start">
                          <Checkbox
                            register={register("assistant_needed")}
                            title="Ich bringe eine Assistenzperson mit"
                          />
                          {assistantNeeded ? (
                            <TextArea
                              register={register("assistant")}
                              placeholder="z. B. Begleitassistenz"
                            />
                          ) : null}
                        </div>
                      </div>
                    </Field>
                    <Field lgSpacing>
                      <div tw="relative col-span-2">
                        <div tw="flex flex-col items-start">
                          <Checkbox
                            register={register("support_needed")}
                            title="Ich brauche Unterstützung"
                          />
                          {supportNeeded ? (
                            <TextArea
                              register={register("support")}
                              placeholder="z. B. Verdolmetschung in Dt. Gebärdensprache, Audiodeskription"
                            />
                          ) : null}
                        </div>
                      </div>
                    </Field>
                    <Field lgSpacing>
                      <div tw="relative col-span-2">
                        <div tw="flex flex-col items-start">
                          <Checkbox
                            register={register("child_support_needed")}
                            title="Ich benötige eine Kinderbetreuung (bitte Alter des Kindes/der Kinder angeben)"
                            label="Bitte Alter des Kindes/der Kinder und gewünschten Betreuungszeitraum angeben. Eine Betreuung ist für Kinder im Alter von 1-10 Jahren möglich. Die Betreuung vor Ort ist zwischen 9 und 18 Uhr möglich."
                          />
                          {childSupportNeeded ? (
                            <TextArea
                              register={register("child_support")}
                              placeholder="z. B. 3 Jahre, 9.30-18:00 Uhr"
                            />
                          ) : null}
                        </div>
                      </div>
                    </Field>
                  </Column>
                </Field>


                <Field lgSpacing>
                  <Input fullwidth register={register("other")} label="Sonstiges" />
                </Field>

                <Field lgSpacing>
                  <div tw="relative col-span-2">
                    <div tw="flex flex-col items-start space-y-3">
                      <Checkbox
                        register={register("picturespermission", {
                          required: true,
                        })}
                        required
                        title="Ich willige in die Anfertigung von Foto- und Filmaufnahmen meiner Person und die Veröffentlichung durch die Bundesstiftung Gleichstellung im Rahmen deren Presse- und Öffentlichkeitsarbeit ein. Meine Einwilligung schließt die Veröffentlichung in Druckerzeugnissen, im Internet und auf Social Media sowie die Übertragung im Wege eines Livestreams ein."
                      />
                      {errors?.picturespermission ? (
                        <div tw="relative text-sm text-red-500">
                          Dies ist ein Pflichtfeld
                        </div>
                      ) : null}
                      <Checkbox
                        register={register("gdprpermission", { required: true })}
                        title="Ich habe den Code of Conduct der Veranstaltung zur Kenntnis genommen und erkenne die Regeln für ein gutes Miteinander an."
                        required
                      />
                      {errors?.gdprpermission ? (
                        <div tw="relative text-sm text-red-500">
                          Dies ist ein Pflichtfeld
                        </div>
                      ) : null}
                      <Checkbox
                        register={register("newsletter")}
                        title="Hiermit stimme ich der Speicherung meiner personenbezogenen Daten im Rahmen des Anmeldeprozesses zu."
                      />
                    </div>
                  </div>
                </Field>

                <Field>
                  <div tw="mb-5 text-sm">
                    <b>Datenverarbeitungshinweis:</b> Wir verarbeiten Ihre personenbezogenen Daten ausschließlich im Rahmen der Durchführung des 2. Gleichstellungstages. Weitere Informationen entnehmen Sie bitte der aktuellen Datenschutzerklärung <u>auf unserer Webseite</u>.</div>
                </Field>


                <div tw="col-span-2 pb-4">
                  <Submit loading={isSubmitting}>Absenden</Submit>
                </div>
              </Box>
            </Field>

            {submitError ? (
              <ErrorMessage
                message={
                  "Leider gab es einen Fehler. Bitte versuchen Sie es noch einmal oder kontaktieren Sie uns unter: "
                }
              >
                <a
                  href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                  tw="text-base"
                >
                  gleichstellungstag@bundesstiftung-gleichstellung.de
                </a>
              </ErrorMessage>
            ) : null}
            {duplicateUserError ? (
              <ErrorMessage message="Die*der Nutzer*in mit dieser E-Mail-Adresse wurde bereits registriert." />
            ) : null}
            <div tw="col-span-2 pb-12">
              <Submit loading={isSubmitting}>Absenden</Submit>
            </div>
          </form>
        </>
      )}
      {isSuccessfullySubmitted && (
        <div tw="mt-12 w-full">
          <SuccessMessage />
        </div>
      )}
    </>
  )
}

export default ApplicationForm
