import React from "react"
import "twin.macro"
import Box from "./box"
import Field from "./field"

const SuccessMessage = () => {
  return (
    <Field lgSpacing>
      <Box sand>
        <div tw="mb-6 text-4xl font-bold">Vielen Dank!</div>
        <div tw="max-w-xl text-lg">
          <p>
            Wir bestätigen Ihnen hiermit den Eingang Ihrer Anmeldung. Bitte
            haben Sie noch ein paar Tage Geduld, bis wir Ihnen Ihre Teilnahme
            verbindlich zusagen können.
          </p>
          <p>
            Wir bemühen uns sehr, Ihre Anmeldung sowie Ihre Wünsche zu
            berücksichtigen. Sollte dies im Einzelfall aus Kapazitätsgründen
            nicht möglich sein, möchten wir schon an dieser Stelle um Ihr
            Verständnis bitten.
          </p>
          <p>Mit vielen Grüßen</p>
          <p>Ihr Team des Gleichstellungstags</p>
        </div>
      </Box>
    </Field>
  )
}

export default SuccessMessage
