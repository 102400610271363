import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Box = ({ children, sand = false }) => {
  const sandStyles = tw`bg-sand-300`
  return (
    <div css={[sand && sandStyles]} tw="p-8">
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Box
