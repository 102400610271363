import React from "react"
import tw from "twin.macro"

const Input = ({
  label,
  register,
  type = "text",
  multiple = false,
  placeholder = "",
  required = false,
  disabled = false,
  fullwidth = false,
  small = false,
  medium = false,
  indent = false,
}) => {
  const fullwidthStyles = tw`w-full`
  const smallStyles = tw`w-full md:w-1/3`
  const mediumStyles = tw`w-full md:w-2/3`
  const indentStyles = tw`mt-3 md:mt-1 md:ml-7`

  return (
    <>
      {label && (
        <label tw="mb-2 block w-full font-regular text-sm leading-none">
          {label}
          {required && "*"}
        </label>
      )}
      <input
        {...register}
        // {...register(name, { required, valueAsNumber })}
        css={[
          tw`border-2 border-transparent px-2 pt-2.5 pb-2.5 text-base outline-none placeholder:text-sm placeholder:text-anthracite-500 invalid:border-magenta-500 focus:border-anthracite-500 focus:ring-transparent disabled:bg-sand-200 md:px-3`,
          fullwidth && fullwidthStyles,
          small && smallStyles,
          medium && mediumStyles,
          indent && indentStyles,
        ]}
        type={type}
        disabled={disabled}
        multiple={multiple && multiple}
        placeholder={placeholder}
      />
    </>
  )
}

export default Input
