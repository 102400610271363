import React from "react"
import "twin.macro"

const AnredeSelect = ({
  name,
  label,
  multiple = false,
  placeholder = "",
  required = false,
  onChange,
}) => {
  return (
    <>
      <label tw="mb-2 block font-regular text-sm leading-none">
        {label}
        {required && "*"}
      </label>
      <select
        tw="w-full border-2 border-transparent px-2 pt-2.5 pb-2.5 text-base outline-none focus:border-anthracite-500 focus:ring-transparent md:px-3"
        name={name}
        multiple={multiple}
        required={required}
        onChange={onChange}
        defaultValue={"Auswählen"}
      >
        <option disabled>divers</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
      </select>
    </>
  )
}

export default AnredeSelect
