import React from "react"
import "twin.macro"

const TextArea = ({
  label = "",
  cols = 33,
  placeholder = "",
  required = false,
  disabled = false,
  register,
}) => {
  return (
    <textarea
      tw="mt-3 h-24 w-full border-2 border-transparent px-2 pt-2.5 pb-2.5 text-base outline-none placeholder:text-sm placeholder:text-anthracite-500 invalid:border-magenta-500 focus:border-anthracite-500 focus:ring-transparent disabled:bg-gray-200 md:mt-1 md:ml-7 md:h-auto md:px-3"
      {...register}
      rows="1"
      label={label}
      cols={cols}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default TextArea
